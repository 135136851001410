/**
 * @ngdoc directive
 * @name loadingIcon
 *
 * @description
 * _Please update the description and restriction._
 *
 * @restrict A
 * */
angular.module('smartpager.shared')
    .component('loadingIcon', {
        template: '<i class="fa fa-circle-o-notch fa-spin"></i>'
    });
