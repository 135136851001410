/**
 * @ngdoc service
 * @name smartpager.shared:ngTableHelper
 *
 * @description
 *
 *
 * */
angular.module('smartpager.shared')
    .service('ngTableHelper', function () {

        this.generateGetterSetter = function (tableParams, property_name) {
            return function (value) {
                if (arguments.length) {
                    var tmp = tableParams.filter();
                    tmp[property_name] = value;
                    tableParams.filter(tmp);

                    // This reload is necessary to fix an issue (SC-2797) where the Add Member to Department modal was
                    // only filtering after the modal was closed and re-opened. Don't remove this unless you can verify
                    // that you don't break that modal. :(
                    tableParams.reload();
                } else {
                    return tableParams.filter()[property_name];
                }
            };
        };
    });
