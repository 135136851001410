/**
 * @ngdoc service
 * @name baseService
 * @description
 * _Please update the description and dependencies._
 *
 * */
angular.module('smartpager.shared')
    .factory('baseService', ["$q", "$http", function ($q, $http) {

        function objToParam(parameters) {
            var params = $.param(parameters);
            return (params ? '?' + params : '');
        }

        function pickPage(length, pageSize) {
            length = ((length && length > 0) ? length : 1);
            return Math.ceil((length + 1) / pageSize);
        }

        function prepareParams(items) {
            var pageSize = 20;

            var pageToLoad = pickPage(items.length, pageSize);
            return {
                page: pageToLoad,
                page_size: pageSize
            };
        }

        return {
            http: function (config) {

                var d = $q.defer();
                var httpTimeout = $q.defer();

                config.timeout = httpTimeout.promise;

                $http(config)
                    .success(function (data) {
                        d.resolve(data);
                    })
                    .error(function (data, status, headers, config) {
                        d.reject({
                            data: data,
                            status: status,
                            headers: headers,
                            config: config
                        });
                    });

                var promise = d.promise;
                promise._httpTimeout = httpTimeout;

                return promise;
            },
            // Cancel the underlying AJAX request associated with the given promise.
            // If the promise has not yet been resolved, this will cause the promise
            // to be rejected with status:0.
            // --
            // NOTE: This will NOT raise an exception if the promise is invalid or
            // already resolved / rejected.
            cancel: function (promise) {
                if (
                    promise &&
                    promise._httpTimeout &&
                    promise._httpTimeout.resolve
                ) {
                    promise._httpTimeout.resolve();
                }
            },

            getData: function (url, extraConfig) {
                var config = _.merge({method: 'GET', url: url}, extraConfig);
                return this.http(config);
            },

            postData: function (url, data) {
                return this.http({method: 'POST', url: url, data: data});
            },

            postFormData: function (url, data) {
                return this.http({
                    method: 'POST',
                    url: url,
                    data: $.param(data),
                    headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}
                });
            },

            putData: function (url, data) {
                return this.http({method: 'PUT', url: url, data: data});
            },

            patchData: function (url, data) {
                return this.http({method: 'PATCH', url: url, data: data});
            },

            deleteData: function (url) {
                return this.http({method: 'DELETE', url: url});
            },

            toUrl: function (originalParameters) {
                var parameters = angular.copy(originalParameters);

                if (parameters.hasOwnProperty('group')) {
                    delete parameters.group;
                }

                _.forOwn(parameters.filter, function (value, key) {
                    if (value) {
                        if (value instanceof Date) {
                            parameters[key] = moment(value).toISOString();
                        } else if (value instanceof moment) {
                            parameters[key] = value.toISOString();
                        } else {
                            parameters[key] = value;
                        }
                    }
                });
                delete parameters.filter;

                var ordering = [];
                _.forOwn(parameters.sorting, function (value, key) {
                    ordering.push(value === 'asc' ? key : '-' + key);
                });
                parameters.ordering = ordering.join(',');
                delete parameters.sorting;

                parameters.page_size = parameters.count;
                delete parameters.count;

                return objToParam(parameters);
            },

            objToParam: objToParam,
            prepareParams: prepareParams,
            pickPage: pickPage
        };
    }]);
