/**
 * @ngdoc directive
 * @name smartpager.shared:greaterEqualThan
 *
 * @description
 *
 *
 * @restrict A
 * */
angular.module('smartpager.shared')
    .directive('greaterEqualThan', function () {
        return {
            require: 'ngModel',
            restrict: 'A',
            scope: {
                greaterEqualThanThisValue: '=greaterEqualThan'
            },
            link: function (scope, elem, attr, ngModelCtrl) {

                ngModelCtrl.$validators.greaterEqualThan = function (modelValue) {
                    if (!modelValue || !scope.greaterEqualThanThisValue) {
                        return true;
                    }
                    return modelValue >= scope.greaterEqualThanThisValue;
                };

                scope.$watch('greaterEqualThanThisValue', function () {
                    ngModelCtrl.$validate();
                });
            }
        };
    });
