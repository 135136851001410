/**
 * @ngdoc directive
 * @name smartpager.shared:convertToNumber
 *
 * @description
 * Allows binding select to a non-string value via ngModel parsing / formatting
 *
 * @restrict A
 * */
angular.module('smartpager.shared')
    .directive('convertToNumber', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function (val) {
                    return parseInt(val, 10);
                });
                ngModel.$formatters.push(function (val) {
                    return '' + val;
                });
            }
        };
    })
    .directive('convertToListOfNumbers', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function (val) {
                    return _.map(val, function (item) {
                        return parseInt(item, 10);
                    });
                });

                ngModel.$formatters.push(function (val) {
                    return _.map(val, function (item) {
                        return '' + item;
                    });
                });
            }
        };
    });
