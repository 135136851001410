angular
  .module("smartpager.shared", [
    "smartpager.configuration",
    "preloaded-partials",
    "ngSanitize",
    "ngCookies",
    "ngMessages",
    "ngAnimate",

    "LocalStorageModule",

    "ngRaven",
    "ui.router",
    "dndLists",

    "ui-notification",
    "ui.select",

    "angularFileUpload",
    "angularAudioRecorder",
    "angularMoment",
    "checklist-model",
    "ui.mask",

    "ngTable",

    "ui.bootstrap.tpls",
    "ui.bootstrap.collapse", //angular-strap is better
    "ui.bootstrap.accordion", //not used, depends on collapse
    "ui.bootstrap.alert",
    "ui.bootstrap.buttons",
    //'ui.bootstrap.carousel',      //not used
    "ui.bootstrap.dateparser",
    "ui.bootstrap.position",
    //'ui.bootstrap.datepicker',    //angular-strap is better
    "ui.bootstrap.dropdown",
    "ui.bootstrap.modal",
    "ui.bootstrap.pagination",
    //'ui.bootstrap.tooltip',       //have to use angular strap because of timepicker dependency in angular-strap
    //'ui.bootstrap.popover',       //depends on tooltip
    "ui.bootstrap.progressbar",
    //'ui.bootstrap.rating',        //not used
    "ui.bootstrap.tabs", //not used
    //'ui.bootstrap.timepicker',    //angular-strap is better
    "ui.bootstrap.typeahead",

    "mgcrea.ngStrap.collapse",
    //'mgcrea.ngStrap.modal',       //tab is not constrained to contents of the modal
    "mgcrea.ngStrap.helpers.dateParser",
    "mgcrea.ngStrap.helpers.dateFormatter",
    "mgcrea.ngStrap.tooltip",
    "mgcrea.ngStrap.datepicker",
    "mgcrea.ngStrap.timepicker"
  ])
  .config(["$compileProvider", "$httpProvider", "$logProvider", "configurationProvider", function(
    $compileProvider,
    $httpProvider,
    $logProvider,
    configurationProvider
  ) {
    var loggingEnabled = configurationProvider.isDebug();

    if (localStorage) {
      var value = localStorage.getItem("debug");
      if (!value) {
        localStorage.setItem("debug", configurationProvider.isDebug());
      }
      loggingEnabled = localStorage.getItem("debug") === "true";
    }
    $logProvider.debugEnabled(loggingEnabled);

    //this setting makes things faster by removing extra classes (ng-scope etc.) from DOM
    //this may break some 3rd party libs, disabled for now
    $compileProvider.debugInfoEnabled(true);

    //this setting combines http requests returning withing ~10ms into single digest cycle speeding things up
    $httpProvider.useApplyAsync(true);
  }])
  .config(["localStorageServiceProvider", function(localStorageServiceProvider) {
    localStorageServiceProvider
      .setPrefix("smartpager-")
      .setStorageType("sessionStorage");
  }])
  .config(["$httpProvider", "authInterceptorProvider", "configurationProvider", function(
    $httpProvider,
    authInterceptorProvider,
    configurationProvider
  ) {
    authInterceptorProvider.setToken(configurationProvider.getToken());
    $httpProvider.interceptors.push("authInterceptor");
  }])
  .config(["uiSelectConfig", function(uiSelectConfig) {
    uiSelectConfig.theme = "bootstrap";
  }])
  .config(["$ravenProvider", "configurationProvider", function($ravenProvider, configurationProvider) {
    // There is a development flag to log errors rather than sending it to Sentry

    var ravenSubmittionsDisabled = configurationProvider.isDebug();
    $ravenProvider.development(ravenSubmittionsDisabled);
  }])
  .config(["$stateProvider", function($stateProvider) {
    $stateProvider.state("root.permissionDenied", {
      url: "permissionDenied",
      templateUrl:
        "/smartpager/angular/shared/controllers/permissionDenied.html",
      data: {
        title: ""
      }
    });
  }])
  .config(["NotificationProvider", function(NotificationProvider) {
    NotificationProvider.setOptions({
      delay: 3000,
      startTop: 32,
      startRight: 32,
      verticalSpacing: 20,
      horizontalSpacing: 20,
      positionX: "right",
      positionY: "top"
    });
  }])
  .run(["ngTableDefaults", function(ngTableDefaults) {
    ngTableDefaults.settings = angular.extend({}, ngTableDefaults.settings, {
      defaultSort: "asc"
    });
  }])
  .run(["heartbeat", function(heartbeat) {
    heartbeat.startBeat();
  }])
  .run(["$rootScope", "$window", "permissionsService", "$state", "modalHelper", "$log", function(
    $rootScope,
    $window,
    permissionsService,
    $state,
    modalHelper,
    $log
  ) {
    $rootScope.$state = $state;

    //block navigation if modal is open
    $rootScope.$on("$stateChangeStart", function(
      event,
      toState,
      toParams,
      fromState,
      fromParams
    ) {
      if (modalHelper.isModalOpen()) {
        modalHelper.dismissAllOpenModals();
      }
    });

    $rootScope.hasPermission = permissionsService.hasPermission;
    $rootScope.hasFeature = permissionsService.hasFeature;

    //check user permission before entering the state
    $rootScope.$on("$stateChangeStart", function(
      event,
      toState,
      toParams,
      fromState,
      fromParams
    ) {
      if (
        toState.data &&
        toState.data.requiredPermissions &&
        toState.data.requiredPermissions.length
      ) {
        //At least one permission is required
        var hasPermission = _.find(toState.data.requiredPermissions, function(
          permission
        ) {
          return permissionsService.hasPermission(permission);
        });

        if (!hasPermission) {
          // If the user doesn't have a permission to a page and a page has defined
          event.preventDefault();
          $state.go("root.permissionDenied");
        }
      }
    });

    $rootScope.$on("$stateChangeSuccess", function(
      event,
      toState,
      toParams,
      fromState,
      fromParams
    ) {
      if ($rootScope.originalDocumentTitle === undefined) {
        $rootScope.originalDocumentTitle = $window.document.title;
      }
      $window.document.title = toState.data.title ?
          $rootScope.originalDocumentTitle + " - " + toState.data.title :
          $rootScope.originalDocumentTitle || "";

      $rootScope.rootPageClass = toState.data.pageClass;
    });

    $rootScope.$on("$stateChangeError", function(
      event,
      toState,
      toParams,
      fromState,
      fromParams,
      error
    ) {
      $log.error(toState, toParams, fromState, fromParams, error);
    });
  }]);
