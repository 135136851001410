/**
 * @ngdoc service
 * @name excludeById
 * @description
 * _Please update the description and dependencies._
 *
 * */
angular.module('smartpager.shared')
    .filter('excludeById', function () {
        return function (array, subs) {
            var out = [];

            if (angular.isArray(array) && angular.isArray(subs)) {
                array.forEach(function (item) {
                    var itemMatches = false;

                    for (var i = 0; i < subs.length; i++) {
                        if (item.id === subs[i].id) {
                            itemMatches = true;

                            break;
                        }
                    }

                    if (!itemMatches) {
                        out.push(item);
                    }
                });
            }

            return out;
        };
    });
