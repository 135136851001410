/**
 * @ngdoc service
 * @name smartpager.shared:eventsBus
 *
 * @description
 *
 *
 * */
angular.module('smartpager.shared')
    .service('eventsBus', ["$rootScope", function ($rootScope) {

        this.subscribe = function (eventName, callback) {
            return $rootScope.$on(eventName, callback);
        };

        this.publish = function (eventName, content) {
            $rootScope.$emit(eventName, content);
        };

    }]);
