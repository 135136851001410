(function () {
    'use strict';


    if (typeof String.prototype.startsWith !== 'function') {
        String.prototype.startsWith = function (str) {
            return this.slice(0, str.length) === str;
        };
    }

//port of .net String.format method
    if (!String.format) {
        String.format = function () {
            var s = arguments[0];
            if (typeof s !== 'undefined') {
                for (var i = 0; i < arguments.length - 1; i++) {
                    var reg = new RegExp('\\{' + i + '\\}', 'gm');
                    s = s.replace(reg, arguments[i + 1]);
                }
            }
            return s;
        };
    }

//standard deviation
    if (!Math.stdDev) {
        Math.standardDeviation = function(values) {
            var avg = Math.average(values);

            var squareDiffs = values.map(function (value) {
                var diff = value - avg;
                var sqrDiff = diff * diff;
                return sqrDiff;
            });

            var avgSquareDiff = Math.average(squareDiffs);

            var stdDev = Math.sqrt(avgSquareDiff);
            return stdDev;
        };
    }

    if (!Math.average) {
        Math.average = function(data) {
            var sum = data.reduce(function (sum, value) {
                return sum + value;
            }, 0);

            return sum / data.length;
        };
    }

    // port of ES6 Number.isInteger method
    Number.isInteger = Number.isInteger || function(value) {
            return typeof value === 'number' &&
                isFinite(value) &&
                Math.floor(value) === value;
        };
})();
