/**
 * @ngdoc service
 * @name permissionsService
 * @description
 * _Please update the description and dependencies._
 *
 * */
angular.module('smartpager.shared')
    .provider('configuration', ["DO_NOT_USE_DIRECTLY_globals", function (DO_NOT_USE_DIRECTLY_globals) {

        var Service = function (globals) {
            this.globals = angular.copy(globals);

            //transform list of permissions to dictionary for quick lookup
            this.globals.currentUser.permissions = globals.currentUser.permissions.reduce(function (obj, currentValue) {
                obj[currentValue] = '';
                return obj;
            }, {});
        };

        Service.prototype.isDebug = function () {
            return this.globals.isDebug;
        };

        Service.prototype.version = function () {
            return this.globals.version;
        };

        Service.prototype.getToken = function () {
            return this.globals.csrfToken;
        };

        Service.prototype.getStaticDir = function () {
            return this.globals.staticDir;
        };

        Service.prototype.getPubnubConfig = function () {
            return this.globals.pubnub.config;
        };

        Service.prototype.getPcmChannelUid = function () {
            return this.globals.pubnub.pcmChannelUid;
        };

        Service.prototype.getNotificationChannelUid = function () {
            return this.globals.pubnub.notificationChannelUid;
        };


        // user profile
        Service.prototype.getUserProfile = function () {
            return this.globals.currentUser;
        };

        Service.prototype.getAccountId = function () {
            return this.globals.currentUser.accountId;
        };

        Service.prototype.getAccountUid = function () {
            return this.globals.currentUser.accountUid;
        };

        Service.prototype.getProviderId = function () {
            return this.globals.currentUser.providerId;
        };

        Service.prototype.getUserId = function () {
            return this.globals.currentUser.id;
        };

        Service.prototype.getUserUid = function () {
            return this.globals.currentUser.userUid;
        };

        Service.prototype.getTimezone = function () {
            return this.globals.currentUser.timezone;
        };

        Service.prototype.getNotificationTones = function () {
            return {
                low: this.globals.currentUser.low_ringtone,
                medium: this.globals.currentUser.medium_ringtone,
                high: this.globals.currentUser.high_ringtone
            };
        };

        Service.prototype.hasPermission = function (permission) {
            return this.globals.currentUser.permissions.hasOwnProperty(permission);
        };

        Service.prototype.hasFeature = function (permission) {
            var legacy_value = this.globals.features[permission];
            if (legacy_value !== undefined) {
                return legacy_value;
            }

            var launchdarkly_value = this.globals.launchdarkly[permission];
            if (launchdarkly_value !== undefined) {
                return launchdarkly_value;
            }

            return false;
        };

        Service.prototype.getClientSetting = function(setting) {
            if (!this.globals.clientsettings) {
                return undefined;
            }

            return this.globals.clientsettings[setting];
        }

        var baseServiceInstance = new Service(DO_NOT_USE_DIRECTLY_globals);

        //-----------------------------------------------------------------------------
        // Provider object,
        // methods added to this will be available in config phase of application
        // everything that is available in config most probably should be available in run phase
        // therefore methods should be implemented on settingsService object and just wrapped below
        //-----------------------------------------------------------------------------
        var provider = {
            $get: function () {
                return baseServiceInstance;
            },
            hasPermission: function (permission) {
                return baseServiceInstance.hasPermission(permission);
            },
            hasFeature: function (permission) {
                return baseServiceInstance.hasFeature(permission);
            },
            getClientSetting: function (setting) {
                return baseServiceInstance.getClientSetting(setting);
            },
            isDebug: function () {
                return baseServiceInstance.isDebug();
            },
            getToken: function () {
                return baseServiceInstance.getToken();
            },
            getStaticDir: function () {
                return baseServiceInstance.getStaticDir();
            }
        };

        return provider;
    }]);
