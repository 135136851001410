/**
 * @ngdoc service
 * @name authInterceptor
 * @description
 *
 * */
angular.module('smartpager.shared')
    .provider('authInterceptor', function () {

        var headerName = 'X-CSRFToken', token, _$log, _$window, _$q;

        var authInterceptor = {
            request: function (config) {
                config.headers = config.headers || {};

                if (token) {
                    config.headers[headerName] = token;
                }
                return config;
            },
            responseError: function (rejection) {
                _$log.warn('request rejected', rejection.status, rejection.config);

                if (rejection.status === 401) {
                    _$window.location.href = '/';
                }
                return _$q.reject(rejection);
            }
        };

        return {
            setHeaderName: function (name) {
                headerName = name || headerName;
            },

            setToken: function (aToken) {
                token = aToken || token;
            },

            $get: ["$log", "$window", "$q", function ($log, $window, $q) {
                _$log = $log;
                _$window = $window;
                _$q = $q;

                return authInterceptor;
            }]
        };
    });
