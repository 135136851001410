/**
 * @ngdoc directive
 * @name smartpager.shared:timezoneSelectDirective
 *
 * @description
 *
 *
 * @restrict A
 * */
angular.module('smartpager.shared')
    .component('timezoneSelect', {
        bindings: {
            label: '@',
            required: '@'
        },
        require: {
            ngModelCtrl: 'ngModel'
        },
        templateUrl: '/smartpager/angular/shared/components/timezoneSelect/timezoneSelect.html',
        transclude: true,
        controller: ['$scope', '$log', function ($scope, $log) {
            var $ctrl = this;

            $ctrl.timezones = [
                'America/Adak',
                'America/Anchorage',
                'America/Boise',
                'America/Cambridge_Bay',
                'America/Chicago',
                'America/Creston',
                'America/Dawson',
                'America/Dawson_Creek',
                'America/Denver',
                'America/Detroit',
                'America/Edmonton',
                'America/Fort_Nelson',
                'America/Glace_Bay',
                'America/Goose_Bay',
                'America/Halifax',
                'America/Indiana/Indianapolis',
                'America/Indiana/Knox',
                'America/Indiana/Marengo',
                'America/Indiana/Petersburg',
                'America/Indiana/Tell_City',
                'America/Indiana/Vevay',
                'America/Indiana/Vincennes',
                'America/Indiana/Winamac',
                'America/Inuvik',
                'America/Iqaluit',
                'America/Juneau',
                'America/Kentucky/Louisville',
                'America/Kentucky/Monticello',
                'America/Los_Angeles',
                'America/New_York',
                'America/North_Dakota/Beulah',
                'America/North_Dakota/Center',
                'America/North_Dakota/New_Salem',
                'America/Phoenix',
                'America/Rainy_River',
                'America/Rankin_Inlet',
                'America/Regina',
                'America/Resolute',
                'America/Rio_Branco',
                'America/Santarem',
                'America/Santo_Domingo',
                'America/Scoresbysund',
                'America/Sitka',
                'America/St_Barthelemy',
                'America/Thule',
                'America/Thunder_Bay',
                'America/Tijuana',
                'America/Toronto',
                'America/Tortola',
                'America/Vancouver',
                'America/Whitehorse',
                'America/Winnipeg',
                'America/Yakutat',
                'America/Yellowknife',
                'Canada/Atlantic',
                'Canada/Central',
                'Canada/Eastern',
                'Canada/Mountain',
                'Canada/Newfoundland',
                'Canada/Pacific',
                'GMT',
                'US/Alaska',
                'US/Arizona',
                'US/Central',
                'US/Eastern',
                'US/Hawaii',
                'US/Mountain',
                'US/Pacific',
                'UTC'
            ];

            $ctrl.$onInit = function () {
                //region handle ngModelCtrl
                $ctrl.ngModelCtrl.$formatters.push(function (modelValue) {
                    return angular.copy(modelValue);
                });

                $ctrl.ngModelCtrl.$parsers.push(function (viewValue) {
                    return angular.copy(viewValue);
                });

                $scope.$watch('$ctrl.timezone', function (item) {
                    $log.debug('timezoneSelect updates ngModel binding', item);
                    $ctrl.ngModelCtrl.$setViewValue(angular.copy(item));
                });

                $ctrl.ngModelCtrl.$render = function () {
                    $ctrl.timezone = $ctrl.ngModelCtrl.$viewValue;
                };

                if ($ctrl.required) {
                    $ctrl.ngModelCtrl.$validators.required = function (modelValue, viewValue) {
                        return !!modelValue;
                    };
                }
                //endregion
            };
        }]
    });
