/**
 * @ngdoc directive
 * @name iqModal
 *
 * @description
 * _Please update the description and restriction._
 *
 * @restrict A
 * */
angular.module('smartpager.shared')
    .directive('iqModal', ["$log", function ($log) {
        return {
            restrict: 'E',
            transclude: true,
            replace: true,
            scope: {
                modalTitle: '@',
                dismiss: '&',
                removeBodyClass: '='
            },
            templateUrl: '/smartpager/angular/shared/components/modalHelper/iqModalDirective.html',
            link: function (scope, elem, attr, ctrl, transclude) {

                //region draggable
                var modal = elem.parent().parent();

                modal.one('mousedown', function () {
                    try {
                        var position = modal.offset();

                        modal.css('margin', '0');
                        modal.css('left', position.left + 'px');
                        modal.css('top', position.top - modal.scrollParent().scrollParent().scrollTop() + 'px');
                    } catch (e) {
                        $log.error('problem with moving a modal', e);
                    }
                });

                modal.draggable({
                    handle: '.modal-header',
                    cursor: 'move',
                    scroll: false
                });
                //endregion

                transclude(function (clone) {
                    angular.forEach(clone, function (cloneEl) {
                        if (cloneEl.nodeName === 'DIV') {
                            // get desired target id
                            var tId = cloneEl.attributes['t-to'].value;
                            if (tId) {
                                // find target element with that id
                                var target = elem.find('[t-id="' + tId + '"]');

                                if (target.length) {
                                    //append element to target
                                    target.append(cloneEl);
                                }
                            }
                        }
                    });
                });
            }
        };
    }]);
