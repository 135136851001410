/**
 * @ngdoc directive
 * @name smartpager.shared:passwordValidationDirective
 *
 * @description
 *
 *
 * @restrict A
 * */
angular.module('smartpager.shared')
    .directive('passwordValidation', function () {
        return {
            require: 'ngModel',
            scope: {
                oldPassword: '=oldPassword'
            },
            link: function (scope, elem, attr, ngModel) {
                ngModel.$validators.matchesOldPassword = function(modelValue){
                    if (!modelValue){
                        return true;
                    }
                    return modelValue !== scope.oldPassword;
                };
            }
        };
    });
