angular.module('smartpager.shared')
    .factory('alertsHelper', function () {

        var Alertable = function () {
            Object.defineProperty(this, 'items', {value: []});
        };

        Alertable.prototype.closeAlert = function (index) {
            this.items.splice(index, 1);
        };

        Alertable.prototype.clearAll = function () {
            this.items.length = 0;
        };

        Alertable.prototype.success = function (message) {
            this.clearAll();
            this.addSuccess(message);
        };

        Alertable.prototype.info = function (message) {
            this.clearAll();
            this.addInfo(message);
        };

        Alertable.prototype.warnings = function (messages) {
            var self = this;
            this.clearAll();
            _.each(messages, function (message) {
                self.addWarning(message);
            });
        };

        Alertable.prototype.warning = function (message) {
            this.clearAll();
            this.addWarning(message);
        };

        Alertable.prototype.dangers = function (messages) {
            var self = this;
            this.clearAll();
            _.each(messages, function (message) {
                self.addDanger(message);
            });
        };

        Alertable.prototype.danger = function (message) {
            this.clearAll();
            this.addDanger(message);
        };

//alias method for danger
        Alertable.prototype.error = function (message) {
            this.danger(message);
        };

//alias method for dangers
        Alertable.prototype.errors = function (messages) {
            this.dangers(messages);
        };

        Alertable.prototype.addInfo = function (message) {
            this.items.push({type: 'info', message: message, prio: allowedTypes.info});
        };

        Alertable.prototype.addSuccess = function (message) {
            this.items.push({type: 'success', message: message, prio: allowedTypes.success});
        };

        Alertable.prototype.addWarning = function (message) {
            this.items.push({type: 'warning', message: message, prio: allowedTypes.warning});
        };

        Alertable.prototype.addDanger = function (message) {

            if (message && typeof(message) === 'object' && message.hasOwnProperty('message')) {
                message = String.format('{0} [{1}: {2}]', message.message, message.exceptionType, message.exceptionMessage);
            }
            this.items.push({type: 'danger', message: message, prio: allowedTypes.danger});
        };

//alias method for danger
        Alertable.prototype.addError = function (message) {
            this.addDanger(message);
        };

        var allowedTypes = {'danger': 20, 'warning': 10, 'success': 5, 'info': 0};

        Alertable.prototype.addRange = function (alerts) {
            var self = this;
            self.clearAll();
            angular.forEach(alerts, function (alert) {
                if (alert.type in allowedTypes === false) {
                    alert.type = 'danger';
                }
                self.items.push({type: alert.type, message: alert.message, prio: allowedTypes[alert.type]});
            });
        };


        return {
            create: function (obj, propName) {
                Object.defineProperty(obj, propName, {value: new Alertable()});
            }
        };
    });
