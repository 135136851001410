/**
 * @ngdoc service
 * @name smartpager.shared:heartbeat
 *
 * @description
 *
 *
 * */
angular.module('smartpager.shared')
    .service('heartbeat', ["baseService", "configuration", "$interval", function (baseService, configuration, $interval) {

        var delta = [0];

        var beat;

        this.startBeat = function () {
            // We don't want heartbeats when no user is logged in due to:
            // * they introduce some traffic, but don't give us any info since we can't assign it to any user,
            // * they end up with 401 response which forces redirect to /, which resets login page every 20s.
            if(configuration.getUserId() > 0) {
                beat = $interval(heartbeat, 20 * 1000);
            }
        };

        this.stopBeat = function () {
            if(beat) {
                $interval.cancel(beat);
            }
        };

        function heartbeat() {
            baseService.getData(String.format('/services/userstatus/?client_time={0}', new Date().getTime()))
                .then(function (response) {
                    var now = new Date().getTime();

                    var roundTrip = now - response.client_time;

                    //I'm using full roundtrip because i have to guess time when server will register received message
                    delta.unshift(Math.floor(response.server_time - now + roundTrip /* /2 */ ));

                    //remember 5 most recent results
                    if(delta.length > 5) {
                        delta.pop();
                    }
                });
        }

        this.getTimeDelta = function () {
            return Math.average(delta);
        };
    }]);
