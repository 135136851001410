/**
 * @ngdoc directive
 * @name smartpager.shared:convertTimeToNumber
 *
 * @description
 * Allows binding select to a non-string value via ngModel parsing / formatting
 *
 * @restrict A
 * */
angular.module('smartpager.shared')
    .directive('convertTimeToNumber', ["$log", function ($log) {
        return {
            require: 'ngModel',
            priority: 1,
            link: function (scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function (val) {
                    $log.debug('parser ' + val);

                    var tmp = moment(val).startOf('minute');
                    var tmp_startOfDay = moment(tmp).startOf('day');
                    var ret_val = (tmp - tmp_startOfDay) / 1000;

                    $log.debug('parser ' + ret_val);

                    return ret_val;
                });

                ngModel.$formatters.push(function (val) {
                    $log.debug('formatter ' + val);
                    if (val % 1 === 0) { // check for integer
                        var ret_val = moment().startOf('day').add(val, 'seconds').toDate();
                        $log.debug('formatter ' + ret_val);
                        return ret_val;
                    } else {
                        return val;
                    }
                });
            }
        };
    }]);
