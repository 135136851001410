/**
 * @ngdoc directive
 * @name smartpager.shared:blankToNull
 *
 * @description
 *
 *
 * @restrict A
 * */
angular.module('smartpager.shared')
    .directive('blankToNull', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                ctrl.$parsers.push(function (viewValue) {
                    if (viewValue === '') {
                        return null;
                    }
                    return viewValue;
                });
            }
        };
    });
