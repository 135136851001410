/**
 * @ngdoc service
 * @name permissionsService
 * @description
 * _Please update the description and dependencies._
 *
 * */
angular.module('smartpager.shared')
    .service('permissionsService', ["$log", "configuration", function ($log, configuration) {

        return {
            hasPermission: function (permission) {
                var hasPermission = configuration.hasPermission(permission);
                if(!hasPermission) {
                    $log.debug('Required permission: ', permission);
                }

                return hasPermission;
            },

            hasFeature: function (feature) {
                return configuration.hasFeature(feature);
            }
        };
    }]);
