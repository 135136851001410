/**
 * @ngdoc directive
 * @name smartpager.shared:lowercaseDirective
 *
 * @description
 *
 *
 * @restrict A
 * */
angular.module('smartpager.shared')
    .directive('lowercase', function () {
        return {
            require: 'ngModel',
            link: function (scope, elem, attr, ngModel) {
                ngModel.$validators.lowercase = function (modelValue){
                    if (!modelValue){
                        return true;
                    }
                    return modelValue.toLowerCase() === modelValue;
                };
            }
        };
});
