/**
 * @ngdoc directive
 * @name smartpager.shared-app:pAlerts
 * @function
 *
 * @description
 * designed to work with alertable behavior
 *
 */
angular.module('smartpager.shared')
    .directive('iqAlerts', function () {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                alerts: '=src'
            },
            templateUrl: '/smartpager/angular/shared/components/iqAlerts/iqAlerts.html'
        };
    });
