/**
 * @ngdoc directive
 * @name smartpager.shared:dateRangePickerDirective
 *
 * @description
 *
 *
 * @restrict A
 * */
angular.module('smartpager.shared')
    .directive('dateRangePicker', ["$log", function ($log) {
        return {
            restrict: 'A',
            scope: {
                options: '=dateRangePicker',
                start: '=dateBegin',
                end: '=dateEnd',
                getterSetter: '='
            },
            link: function (scope, element, attr) {
                if (scope.getterSetter) {
                    scope.options.startDate = scope.start();
                    scope.options.endDate = scope.end();
                } else {
                    scope.options.startDate = scope.start;
                    scope.options.endDate = scope.end;
                }

                function rangeChanged(start, end) {
                    if (scope.getterSetter) {
                        if (scope.start) {
                            scope.start(new Date(start));
                        }
                        if (scope.end) {
                            scope.end(new Date(end));
                        }
                    } else {
                        if (scope.start) {
                            scope.start = new Date(start);
                        }
                        if (scope.end) {
                            scope.end = new Date(end);
                        }
                    }
                    scope.$apply();
                }

                var drp = element.daterangepicker(scope.options, rangeChanged)
                    .data('daterangepicker');

                scope.$watch('start', function (val) {
                    if (scope.getterSetter) {
                        $log.debug('setStartDate', val());
                        drp.setStartDate(val());
                    } else {
                        $log.debug('setStartDate', val);
                        drp.setStartDate(val);
                    }
                });

                scope.$watch('end', function (val) {
                    if (scope.getterSetter) {
                        $log.debug('setEndDate', val());
                        drp.setEndDate(val());
                    } else {
                        $log.debug('setEndDate', val);
                        drp.setEndDate(val);
                    }
                });

                scope.$on('$destroy', function () {
                    drp.remove();
                });
            }
        };
    }]);
