angular.module('smartpager.shared')
    .controller('modalHelperCtrl', ["$scope", "modalOptions", function ($scope, modalOptions) {
        $scope.modalOptions = modalOptions;

        $scope.click = function (id) {
            if (id === 'cancel') {
                $scope.$dismiss();
            } else {
                $scope.$close(id);
            }
        };
    }]);
