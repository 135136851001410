/**
 * @ngdoc directive
 * @name pageHeader
 *
 * @description
 * _Please update the description and restriction._
 *
 * @restrict E
 * */
angular.module('smartpager.shared')
    .directive('pageHeader', function () {
        return {
            restrict: 'E',
            transclude: {
                'breadcrumbs': '?breadcrumbs',
                'buttons': '?buttons'
            },
            templateUrl: '/smartpager/angular/shared/components/pageHeader/pageHeader.html',
            scope: {
                heading: '@',
                headingIcon: '@'
            }
        };
    });
