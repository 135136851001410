angular.module('smartpager.shared')
    .factory('modalHelper', ["$uibModal", function ($uibModal) {
        var Service = {};
        var openModals = {};
        var id = 0;

        function openModal(params) {
            var modalOptions = {};

            modalOptions.controller = 'controller' in params ? params.controller : 'modalHelperCtrl';
            modalOptions.controllerAs = 'ctrl';
            modalOptions.templateUrl = 'templateUrl' in params ? params.templateUrl : '/smartpager/angular/shared/components/modalHelper/modalDialog.html';
            modalOptions.size = params.size;
            modalOptions.backdrop = 'backdrop' in params ? params.backdrop : true;
            modalOptions.keyboard = 'keyboard' in params ? params.keyboard : true;
            modalOptions.resolve = {
                modalOptions: function () {
                    return params;
                }
            };

            var modalInstance = $uibModal.open(modalOptions);

            modalInstance.result
                .finally(function () {
                    delete openModals[id];
                });
            openModals[id++] = modalInstance;

            return modalInstance;
        }

        Service.isModalOpen = function () {
            return _.keys(openModals).length;
        };

        Service.dismissAllOpenModals = function () {
            for (var key in openModals) {
                if (openModals.hasOwnProperty(key)) {
                    openModals[key].dismiss('aborted');
                }
            }
        };

        Service.openModal = function (params) {
            return openModal(params);
        };

        return Service;
    }]);
