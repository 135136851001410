/**
 * @ngdoc directive
 * @name smartpager.shared:fileDownload
 *
 * @description
 *
 *
 * @restrict A
 * */
angular.module('smartpager.shared')
    .component('fileDownload', {
        templateUrl: '/smartpager/angular/shared/components/fileDownload/fileDownload.html',
        bindings: {
            url: '@',
            tooltiptext: '@'
        },
        controller: ['permissionsService', function (permissionsService) {
            this.hasPermission = function (permission) {
                return permissionsService.hasPermission(permission);
            };
        }]
    });
