/**
 * @ngdoc directive
 * @name smartpager.shared:resetValidityDirective
 *
 * @description
 *
 *
 * @restrict A
 * */
angular.module('smartpager.shared')
    .directive('resetValidity', ["eventsBus", function (eventsBus) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elem, attr, ngModel) {
                eventsBus.subscribe('serverError', function(e, errors){
                    if(attr.name in errors){
                        ngModel.$setValidity('server', false);
                    }
                });

                ngModel.$parsers.push(function (value) {
                    if (ngModel.$error.server) {
                        ngModel.$setValidity('server', true);
                    }
                    return value;
                });
                scope.$on('$destroy', eventsBus.subscribe('serverError', function (e, errors) {
                    if (attr.name in errors) {
                        ngModel.$setValidity('server', false);
                    }
                }));
            }
        };
    }]);
