/**
 * @ngdoc service
 * @name smartpager.shared:phoneNumberFilter
 *
 * @description
 *
 *
 * */
angular.module('smartpager.shared')
    .filter('phoneNumberFilter', function () {
        return function (input) {
            if (!input) {
                return '';
            }

            var value = input.toString().trim().replace(/^\+/, '');

            if (value.match(/[^0-9]/)) {
                return input;
            }

            var country, city, number;

            switch (value.length) {
                case 10:
                    country = 1;
                    city = value.slice(0, 3);
                    number = value.slice(3);
                    break;
                case 11:
                    country = value[0];
                    city = value.slice(1, 4);
                    number = value.slice(4);
                    break;

                case 12:
                    country = value.slice(0, 3);
                    city = value.slice(3, 5);
                    number = value.slice(5);
                    break;

                default:
                    return input;
            }

            /* jshint eqeqeq: false */
            if (country == 1) {
                country = '';
            }

            number = number.slice(0, 3) + '-' + number.slice(3);
            return (country + ' (' + city + ') ' + number).trim();
        };
    });
